import { extend } from 'vee-validate'
import { required, required_if, email, confirmed, min_value, max_value, min, length } from 'vee-validate/dist/rules'

extend('min', min);
extend('length', length);
extend('min_value', { ...min_value, params: ['min'], message: "Minimum {min}." })
extend('max_value', { ...max_value, params: ['max'], message: "Maximum {max}." })
extend('email', { ...email, message: "Please provide a valid email address." })
extend('required', { ...required, message: 'Field is required.' })
extend('required_if', { ...required_if, message: 'Field is required.' })
extend('confirmed', { ...confirmed, message: 'Password fields must match.' })
extend('phone', value => {
    if (/^\+?[\d ]{5,}$/.test(value))
        return true
    return 'At least 5 digits (can start with "+")'
})
extend('password', value => {
    if (/^(?=.*[0-9])(?=.*[^0-9]).{8,}$/.test(value))
        return true
    return 'At least 8 characters and 1 number'
})
extend('contentUrl', value => {
    if (/^([\w-]+[/]?)+[.][\w]+$/.test(value))
        return true
    return 'File path must be without whitespaces and with a valid file extension.'
})
extend('imageUrl', value => {  
    if (/^([\w-]+[/]?)+[.](jpg|png|webp|jpeg|gif|svg|apng|avif|jfif)$/i.test(value))
        return true
    return 'Image file path must be without whitespaces and with a valid image file extension.'
})
extend('videoUrl', value => {
    if (/^([\w-]+[/]?)+[.](mp4|mkv|mov|avi|wmv|avchd|webm)$/i.test(value))
        return true
    return 'Video file path must be without whitespaces and with a valid video file extension.'
})
extend('proxyFile', value => {
    if (/^proxy$/i.test(value))
        return true
    return 'This file is only used by the dashboard, filename should contain "proxy", otherwise will be pushed to devices unnecessarily.'
})
extend('webContentRootPath', value => {
    if (/^([a-zA-Z0-9_-]+[/])+$/.test(value))
        return true
    return 'webContentRootPath must be without whitespaces, ending with a / (slash)'
})